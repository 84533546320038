<template>
     <Toolbar>
        <template v-slot:left>
            <h5 class="p-m-0">DID Numbers</h5>
        </template>
    </Toolbar>
     <TabMenu :model="items" />
     <router-view />
</template>

<script>
import TabMenu from 'primevue/tabmenu';
export default {
    components:{TabMenu},
  name: "headerComponent",
  data() {
    return {
        items: [
                {label: 'Free Numbers', icon: 'pi pi-phone', to: '/D_I_D/Numbers/free-num%bers' },
                {label: 'Client Numbers', icon: 'pi pi-users', to: '/D_I_D/Numbers/client-num%bers'},
                {label: 'Under Notice Period Numbers', icon: 'pi pi-times-circle', to: '/D_I_D/Numbers/notice-period'},
                {label: 'Request Submitted to Operator', icon: 'pi pi-times-circle', to: '/D_I_D/Numbers/submitted-request-to-operator'},
                {label: 'Released Numbers', icon: 'pi pi-times-circle', to: '/D_I_D/Numbers/released-numbers'}
            ],
    };
  },
};
</script>